import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import '../src/css/icon-font.css'
import '../src/css/style.css'
import logoImage from '../src/img/vimlogo2.png'
// import img1 from '../src/img/img1.png'
// import img2 from '../src/img/img2.png'
// import img3 from '../src/img/img3.png'

function App() {
  return (
      <div className="App">
        <header className={'header'}>
          <div className={'header__logo-box'}><img src={logoImage} className={'header__logo'} alt={"logo"}/></div>

          <div className={'header__text-box'}>
            <h1 className={'heading-primary'}>
              <span className={'heading-primary--main'} data-testid={'heading'}>Under construction..</span>
              <span className={'heading-primary--main'} data-testid={'heading'}>Vim Portal Try</span>
              <span className={'heading-primary--sub'} data-testid={'sub-heading'}>Property Management</span>
            </h1>

            <a href={"Portal"} className={'btn btn--white btn--animated'} data-testid={'linkButton_login'}>Access your Portal</a>
          </div>
        </header>

        <main>
          {/*<section className="section-about">*/}
          {/*  <div className="u-center-text u-margin-bottom-big">*/}
          {/*    <h2 className="heading-secondary">*/}
          {/*      Manage your property with ease!*/}
          {/*    </h2>*/}
          {/*  </div>*/}

          {/*  <div className={'row'}>*/}
          {/*    <div className={'col-1-of-2'}>*/}
          {/*      <h3 className="heading-tertiary u-margin-bottom-small">Vimportal property management tool is free</h3>*/}
          {/*      <p className="paragraph">*/}
          {/*        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus aperiam, assumenda dicta dolores explicabo in, ipsam mollitia necessitatibus odit provident quibusdam repellat repellendus totam ullam veritatis vitae voluptatum.*/}
          {/*      </p>*/}

          {/*      <h3 className="heading-tertiary u-margin-bottom-small">Improve landlord and tenant communication</h3>*/}
          {/*      <p className="paragraph">*/}
          {/*        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid aperiam consequuntur enim eveniet, expedita facere ipsa minima omnis! Ad aliquid consequatur molestiae qui unde! Enim hic nam necessitatibus quam qui?*/}
          {/*      </p>*/}

          {/*      <a href="More" className="btn-text">Learn more &rarr;</a>*/}
          {/*    </div>*/}
          {/*    <div className={'col-1-of-2'}>*/}
          {/*      <div className="composition">*/}
          {/*        <img src={img1} alt="pic1" className="composition__photo composition__photo--p1"/>*/}
          {/*        <img src={img2} alt="pic2" className="composition__photo composition__photo--p2"/>*/}
          {/*        <img src={img3} alt="pic3" className="composition__photo composition__photo--p3"/>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</section>*/}

          {/*<section className="section-features">*/}

          {/*  <div className="row">*/}
          {/*    <div className="col-1-of-4">*/}
          {/*      <div className="feature-box">*/}
          {/*        <i className={"feature-box__icon icon-basic-home"}></i>*/}
          {/*        <h3 className="heading-tertiary u-margin-bottom-small">Register Property</h3>*/}
          {/*        <p className="feature-box__text">*/}
          {/*          Register your properties and management them for free.*/}
          {/*        </p>*/}
          {/*      </div>*/}
          {/*    </div>*/}

          {/*    <div className="col-1-of-4">*/}
          {/*      <div className="feature-box">*/}
          {/*        <i className={"feature-box__icon icon-basic-settings"}></i>*/}
          {/*        <h3 className="heading-tertiary u-margin-bottom-small">Maintenance</h3>*/}
          {/*        <p className="feature-box__text">*/}
          {/*          Assign jobs to maintenance companies local to your properties.*/}
          {/*        </p>*/}
          {/*      </div>*/}
          {/*    </div>*/}

          {/*    <div className="col-1-of-4">*/}
          {/*      <div className="feature-box">*/}
          {/*        <i className={"feature-box__icon icon-basic-hammer"}></i>*/}
          {/*        <h3 className="heading-tertiary u-margin-bottom-small">Contractor</h3>*/}
          {/*        <p className="feature-box__text">*/}
          {/*          Draft lease agreement between landlord and tenant per state law.*/}
          {/*        </p>*/}
          {/*      </div>*/}
          {/*    </div>*/}

          {/*    <div className="col-1-of-4">*/}
          {/*      <div className="feature-box">*/}
          {/*        <i className={"feature-box__icon icon-basic-notebook-pen"}></i>*/}
          {/*        <h3 className="heading-tertiary u-margin-bottom-small">Lease Agreement</h3>*/}
          {/*        <p className="feature-box__text">*/}
          {/*          View all lease agreement agree for your properties in one place*/}
          {/*        </p>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</section>*/}

          {/*<section className="popular-products">*/}
          {/*  <div className="u-center-text u-margin-bottom-big">*/}
          {/*    <h2 className="heading-secondary">*/}
          {/*      Popular Products*/}
          {/*    </h2>*/}
          {/*  </div>*/}

          {/*  <div className={'row'}>*/}
          {/*      <div className={'col-1-of-3'}>*/}
          {/*          <div className="card">*/}
          {/*            <div className="card__side card__side--front">*/}
          {/*              Front*/}
          {/*            </div>*/}
          {/*            <div className="card__side card__side--back card__side--back-1">*/}
          {/*              Back*/}
          {/*            </div>*/}
          {/*          </div>*/}
          {/*      </div>*/}
          {/*      <div className={'col-1-of-3'}>*/}
          {/*          Col 1 of 3*/}
          {/*      </div>*/}
          {/*      <div className={'col-1-of-3'}>*/}
          {/*          Col 1 of 3*/}
          {/*      </div>*/}
          {/*  </div>*/}
          {/*</section>*/}
        </main>
        {/*<section className={'gid-test'}>*/}
        {/*    <div className={'row'}>*/}
        {/*        <div className={'col-1-of-2'}>*/}
        {/*            Col 1 of 2*/}
        {/*        </div>*/}
        {/*        <div className={'col-1-of-2'}>*/}
        {/*            Col 1 of 2*/}
        {/*        </div>*/}
        {/*    </div>*/}

        {/*    <div className={'row'}>*/}
        {/*        <div className={'col-1-of-3'}>*/}
        {/*            Col 1 of 3*/}
        {/*        </div>*/}
        {/*        <div className={'col-1-of-3'}>*/}
        {/*            Col 1 of 3*/}
        {/*        </div>*/}
        {/*        <div className={'col-1-of-3'}>*/}
        {/*            Col 1 of 3*/}
        {/*        </div>*/}
        {/*    </div>*/}

        {/*    <div className={'row'}>*/}
        {/*        <div className={'col-1-of-3'}>*/}
        {/*            Col 1 of 3*/}
        {/*        </div>*/}
        {/*        <div className={'col-2-of-3'}>*/}
        {/*            Col 2 of 3*/}
        {/*        </div>*/}
        {/*    </div>*/}

        {/*    <div className={'row'}>*/}
        {/*        <div className={'col-1-of-4'}>*/}
        {/*            Col 1 of 4*/}
        {/*        </div>*/}
        {/*        <div className={'col-1-of-4'}>*/}
        {/*            Col 1 of 4*/}
        {/*        </div>*/}
        {/*        <div className={'col-1-of-4'}>*/}
        {/*            Col 1 of 4*/}
        {/*        </div>*/}
        {/*        <div className={'col-1-of-4'}>*/}
        {/*            Col 1 of 4*/}
        {/*        </div>*/}
        {/*    </div>*/}

        {/*    <div className={'row'}>*/}
        {/*        <div className={'col-1-of-4'}>*/}
        {/*            Col 1 of 4*/}
        {/*        </div>*/}
        {/*        <div className={'col-1-of-4'}>*/}
        {/*            Col 1 of 4*/}
        {/*        </div>*/}
        {/*        <div className={'col-2-of-4'}>*/}
        {/*            Col 2 of 4*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*    <div className={'row'}>*/}
        {/*        <div className={'col-1-of-4'}>*/}
        {/*            Col 1 of 4*/}
        {/*        </div>*/}
        {/*        <div className={'col-3-of-4'}>*/}
        {/*            Col 3 of 4*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</section>*/}

      </div>
  );
}

export default App;
